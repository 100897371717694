import { createRouter, createWebHistory } from 'vue-router'
import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/explore/',
        name: 'explore',
        component: () => import('@/views/ExploreView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/:shopSlug',
        name: 'shop',
        component: () => import('@/views/ShopView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/item/:itemSlug',
        name: 'item',
        component: () => import('@/views/ItemView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/item-rating-and-review/id/:itemSlug',
        name: 'item-rating-and-review',
        component: () => import('@/views/ItemRatingAndReviewView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signin/',
        name: 'sign-in',
        component: () => import('../views/SigninView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/signup/',
        name: 'sign-up',
        component: () => import('@/views/SignupView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/forgot-password/',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPasswordView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/reset-password/',
        name: 'reset-password',
        component: () => import('@/views/ResetPasswordView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/cart/',
        name: 'cart',
        component: () => import('@/views/CartView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/checkout/:checkoutStep',
        name: 'checkout',
        component: () => import('@/views/CheckoutView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/checkout/order-placed/:orderId',
        name: 'order-confirmed',
        component: () => import('@/views/OrderConfirmedView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/employee-invite/',
        name: 'employee-invite',
        component: () => import('@/views/EmployeeInviteView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/verify-email/',
        name: 'verify-email',
        component: () => import('@/views/VerifyEmailView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'not-found',
        component: () => import('@/views/NotFoundView.vue'),
        meta: {
            requiresAuth: false
        }
    },


    // User account routes
    {
        path: '/accounts/orders/',
        name: 'orders',
        component: () => import('@/views/accounts/OrdersView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/order/id/:orderId',
        name: 'order-details',
        component: () => import('@/views/accounts/OrderDetailsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/saved-items/',
        name: 'saved-items',
        component: () => import('@/views/accounts/SavedItemsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/profile/:action/',
        name: 'profile',
        component: () => import('@/views/accounts/ProfileView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/address/:action?/',
        name: 'address-book',
        component: () => import('@/views/accounts/AddressView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/address/create/',
        name: 'address-create',
        component: () => import('@/views/accounts/AddressCreateView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/address/edit/:addressId',
        name: 'address-edit',
        component: () => import('@/views/accounts/AddressEditView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/change-password/',
        name: 'change-password',
        component: () => import('@/views/accounts/ChangePasswordView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/accounts/signout/',
        name: 'sign-out',
        component: () => import('@/views/accounts/SignoutView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/terms-of-service/',
        name: 'terms-of-service',
        component: () => import('@/views/legal/TermsOfServiceView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/general-privacy-policy/',
        name: 'general-privacy-policy',
        component: () => import('@/views/legal/GeneralPrivacyPolicyView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/merchants-privacy-policy/',
        name: 'merchants-privacy-policy',
        component: () => import('@/views/legal/MerchantsPrivacyPolicyView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/buyers-privacy-policy/',
        name: 'buyers-privacy-policy',
        component: () => import('@/views/legal/BuyersPrivacyPolicyView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/site-visitors-privacy-policy/',
        name: 'site-visitors-privacy-policy',
        component: () => import('@/views/legal/SiteVisitorsPrivacyPolicyView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/acceptable-use-policy/',
        name: 'acceptable-use-policy',
        component: () => import('@/views/legal/AcceptableUsePolicyView.vue'),
        meta: {
            requiresAuth: false
        }
    },
    {
        path: '/delivery-and-returns-policy/',
        name: 'delivery-and-returns-policy',
        component: () => import('@/views/legal/DeliveryAndReturnsPolicyView.vue'),
        meta: {
            requiresAuth: false
        }   
    },
    {
        path: '/covid-19-products-rules-of-engagement/',
        name: 'covid-19-products-rules-of-engagement',
        component: () => import('@/views/legal/Covid19ProductsRulesOfEngagementView.vue'),
        meta: {
            requiresAuth: false
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition){
        if(savedPosition){
            return savedPosition
        }else if(to.hash){
            return { el: to.hash }
        }else{
            return { left: 0, top: 0 }
        }
    }
})

router.beforeEach((to, from, next) => {
    axios.get(`/user/auth/status`)
    .then((response) => {

        const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

        if(requiresAuth && !response.data.allow){
            next({ name: 'sign-in', query: { 'next': encodeURIComponent(to.fullPath) } })
        }else{
            next()
        }

    })
})

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start()
    }
    next()
})
  
router.afterEach(() => {
    NProgress.done()
})
  

export default router
