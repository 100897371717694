import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import './axios'
import mitt from 'mitt'
import Notifications from '@kyvg/vue3-notification'

const emitter = mitt()
const app = createApp(App)

app.use(router)
app.use(VueCookies)
app.use(Notifications)
app.config.globalProperties.emitter = emitter
app.mount('#app')
