<template>
    <div>
        <notifications classes="custom-notification" />
        <router-view />
    </div>
</template>

<style>
    @import '../src/assets/output.css';
    html, body {
        font-family: 'Poppins', sans-serif;
        background-color: #f5f5f5;
        margin: 0;
        padding: 0;
    }
    /* devtools */
    .vue-notification-group{
        max-width: 32rem;
        width: 100% !important;
        left: 0;
        right: 0;
        margin: auto;
    }
    .custom-notification{
        color: rgb(245, 245, 245);
        padding: 10px 30px;
        margin: 5px 5px;
        border-radius: 4px;
    }
    .custom-notification{
        background-color: rgb(40, 40, 40) !important;
    }
    .custom-notification.success{
        background-color: rgb(34, 197, 94) !important;
    }
    .custom-notification.info{
        background-color: rgb(40, 40, 40) !important;
    }
    .custom-notification.error{
        background-color: rgb(239, 68, 68) !important;
    }
</style>